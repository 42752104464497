@import "styles/variable";

.apartment {
  position: relative;
  z-index: 1;
  margin-top: -100px;
  .content {
    display: flex;
    align-items: flex-start;
  }
}
.choice {
  .title {
    font-size: 48px;
    line-height: 56px;
    text-transform: uppercase;
    position: relative;
    z-index: 124;
    margin-bottom: 30px;
  }
  .white {
    color: #fff;
    position: absolute;
    top: 79px;
    left: 66px;
  }
  .text {
    position: absolute;
    color: #fff;
    top: 203px;
    left: 66px;
  }
  .item {
    position: relative;
    transition: 1s;
  }
  .left {
    right: 80px;
    bottom: 80px;
    opacity: 0;
  }
  .right {
    left: 80px;
    top: 80px;
    opacity: 0;
  }
  .animation {
    &.left {
      right: 0;
      bottom: 0;
      opacity: 1;
    }
    &.right {
      left: 0;

      top: 0;
      opacity: 1;
    }
  }
  .parameters {
    margin-top: 20px;
    padding: 60px 66px 41px;
    background: #fff;
    box-shadow: 0 24px 74px rgba(0, 0, 0, 0.06);
    min-height: 680px;
    P {
      font-size: 20px;
      &:nth-last-of-type(1){
        margin-bottom: 20px;
      }
    }
    :global{
      .parametrs__btn{
        margin: 0;
      }
    }
  }
  .visual {
    img {
      width: 100%;
      display: block;
    }
  }
  .button {
    margin-top: 26px;
  }

  .corpus {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .building {
    position: absolute;
    z-index: 1;
    font-size: 10px;
    display: flex;
    color: #fff;
    align-items: stretch;
    cursor: pointer;
  }
  .first {
    left: 48.5%;
    top: 43%;
  }
  .second {
    left: 53.75%;
    top: 37.5%;
  }
  .bubble {
    position: relative;
    width: 50px;
    height: 78px;
    background: $color-blue-dark;
    text-align: center;
    font-family: $font-geo;
    padding: 12px 0;
    margin-right: 1px;
    div {
      font-size: 30px;
      line-height: 38px;
      font-weight: 300;
    }
    &::before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      top: 100%;
      left: 0;
      border: 6px solid $color-blue-dark;
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
  }
  .info {
    max-width: 71px;
    padding: 20px 8px 12px;
    background: rgba(30, 50, 75, 0.9);
    line-height: 15px;
  }
  .data {
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    bottom: 0;
    left: 0;
    @media(max-width: 1168px) {
      bottom: 3%;
    }
    @media(max-width: 700px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .cell {
    width: calc(50% - 1px);
    background: $color-blue-dark;
    color: #fff;
    padding: 12px 0 24px 20px;
    font-weight: 300;

    p {
      font-size: 14px;
    }

    span {
      font-size: 38px;
    }

    .sup {
      position: absolute;
      top: 39px;
    }

    .rouble{
      font-weight: normal !important;
    }

    @media(max-width: 700px) {
      width: 100%;
      max-width: 400px;
      &:nth-of-type(1) {
        margin-bottom: 2px;
      }
    }
  }
}


@media all and (max-width: 1439px) {
  .choice {
    .title {
      font-size: 44px;
      br {
        display: none;
      }
    }
    .white {
      top: 60px;
    }
    .text {
      top: 171px;
    }
    .parameters {
      min-height: 550px;
    }
    .cell {
      span {
        font-size: 28px;
      }
    }
    .rouble {
    }
  }
}

@media all and (max-width: 1168px) {
  .choice {
    .white {
      top: 40px;
    }
    .text {
      top: 148px;
    }
    .item {
      width: 50%;
      margin-right: 0;
    }
    .cell {
      span {
        font-size: 24px;
      }
    }
    .rouble {
      &::before {
        top: 0;
      }
    }
  }
  .apartment {
    .content {
      align-items: stretch;
    }
  }
}

@media all and (max-width: 940px) {
  .apartment {
    .content {
      flex-wrap: wrap;
    }
  }
  .choice {
    .item {
      width: calc(100% + 80px);
      margin: 0px -40px;
    }
    .parameters {
      min-height: auto;
      padding-bottom: 54px;
    }
    .title {
      max-width: 502px;
      line-height: 56px;
    }
    .white {
      left: 40px;
    }
    .text {
      top: 170px;
      left: 40px;
    }
    .parameters {
      padding: 47px 40px 54px;
    }
    .cell {
      p {
        margin-top: 3px;
      }
      span {
        font-size: 40px;
      }
    }
  }
}

@media all and (max-width: 840px) {
  .choice .first {
  }
}

@media all and (max-width: 700px) {
  .apartment {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .choice {
    .animation {
      &.right {
        overflow: visible;
      }
    }
    .visual {
      img {
        margin: 0 0 100px 0;
      }
    }
    .first {
      top: 30%;
    }
    .parameters {
      padding: 32px 20px 36px;
      box-shadow: none;
      p {
        font-size: 18px;
      }
    }
    .item {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }
    .text {
      top: 132px;
      left: 20px;
    }
    .title {
      font-size: 30px;
      line-height: 40px;
    }
    .white {
      left: 20px;
      top: 32px;
    }
    .visual {
      padding: 0;
    }
    .data {
      display: block;
      bottom: 0;
    }
    .cell {
      width: 100%;
      max-width: 280px;
      margin: 0 auto 1px;
      text-align: center;
      padding: 12px 0;
      span {
        font-size: 30px;
      }
    }
  }
}

@media all and (max-width: 420px) {
  .parameters .button {
    width: 100%;
    text-align: center;
  }
}

.right {
  margin-top: -45px;
  @media(max-width: 1168px) {
    margin-top: 0px;
  }
  @media(max-width: 940px) {
    margin-top: 0px;
  }
}
